<template>
  <div class="page">
    <img
      src="https://static.zyoperate.com/front/image/douyin/ttb831ce8c270d08b401.jpg"
      alt=""
    />
  </div>
</template>

<script>
  export default {}
</script>

<style lang="less" scoped>
    // body {
    //   /* font-family: PingFangSC-Regular, PingFang SC; */
    //   -webkit-text-size-adjust: 100% !important;
    //   text-size-adjust: 100% !important;
    //   -moz-text-size-adjust: 100% !important;
    //   -webkit-text-size-adjust: none !important;
    // }

    // body,
    // a {
    //   padding: 0;
    //   margin: 0;
    // }

    [v-cloak] {
      display: none;
    }
    
    .page img {
      display: block;
      width: 100%;
      margin: auto;
    }
</style>
